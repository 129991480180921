export const consoleLogFilterer = () => {
  //test no filter
  if (process.env.NODE_ENV === 'production') {
    console.log('CONSOLE LOG DISABLED');
    const noop = () => {};
    [
      'assert',
      'clear',
      'count',
      'debug',
      'dir',
      'dirxml',
      'error',
      'exception',
      'group',
      'groupCollapsed',
      'groupEnd',
      'info',
      'log',
      'markTimeline',
      'profile',
      'profileEnd',
      'table',
      'time',
      'timeEnd',
      'timeline',
      'timelineEnd',
      'timeStamp',
      'trace',
      'warn',
    ].forEach(method => {
      window.console[method] = noop;
    });
  }
};
