import { notification, message, Modal, Button } from 'antd';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { logout } from '../helper/auth';
import { getItem, removeItem, setItem } from './localStorage';

const { confirm } = Modal;
const AVATAR_COLORS = [
  '232, 105, 156',
  '255, 198, 115',
  '128, 128, 255',
  '105, 232, 194',
  '234, 255, 128',
];
const AVATAR_OPACITY = 0.4;

export const openNotificationWithIcon = (
  type,
  message,
  description,
  duration,
  placement,
) => {
  let config = {
    message: message || '',
    description: description || '',
  };
  notification.config({
    duration: duration && duration > 1 ? duration : 2,
    placement: placement || 'topRight',
    // closeIcon: true,
  });
  notification[type](config);
};

export const openMessageWithIcon = (type, content, duration) => {
  message[type](
    content ? content : '',
    duration && duration > 1 ? duration : 2,
  );
};

export const openConfirmModal = (
  onOk,
  title = 'Notification',
  description = 'Are you sure?',
  onCancel = () => {},
  okButtonText = 'Submit',
  cancelButtonText = 'Cancel',
) => {
  confirm({
    title: title,
    icon: <WarningOutlined />,
    content: description,
    okText: okButtonText,
    cancelText: cancelButtonText,
    onOk: () => onOk(),
    onCancel: () => onCancel(),
    maskTransitionName: '',
    transitionName: '',
  });
};

export const openAlertModal = async (
  type,
  message,
  description,
  duration = 5,
) => {
  let sessionExpired = await getItem('sessionExpired');
  let alertError = await getItem('alertError');
  if (alertError !== null) await removeItem('alertError');
  if (!sessionExpired) {
    await setItem('alertError', 1);
    let modal = null;
    if (type === 'warning') {
      modal = Modal.warning({
        title: message,
        content: description,
        closable: true,
        centered: true,
        onOk: async () => {
          await removeItem('alertError');
        },
        onCancel: async () => {
          await removeItem('alertError');
        },
      });
    } else if (type === 'error') {
      modal = Modal.error({
        title: message,
        content: description,
        closable: true,
        centered: true,
        onOk: async () => {
          await removeItem('alertError');
        },
        onCancel: async () => {
          await removeItem('alertError');
        },
      });
    } else if (type === 'info') {
      modal = Modal.info({
        title: message,
        content: description,
        closable: true,
        centered: true,
        onOk: async () => {
          await removeItem('alertError');
        },
        onCancel: async () => {
          await removeItem('alertError');
        },
      });
    } else {
      modal = Modal.success({
        title: message,
        content: description,
        closable: true,
        centered: true,
        onOk: async () => {
          await removeItem('alertError');
        },
        onCancel: async () => {
          await removeItem('alertError');
        },
      });
    }
    setTimeout(async () => {
      modal.destroy();
      await removeItem('alertError');
    }, duration * 1000);
  }
};

export const openSessionExpiredModal = async (secondsToGo = 5) => {
  await setItem('sessionExpired', 1);
  const modal = Modal.warning({
    title: 'Cảnh báo !',
    content: `Phiên làm việc đã hết hạn. Quay lại trang đăng nhập sau ${secondsToGo} giây!`,
    onOk: () => {
      logout();
    },
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
    if (secondsToGo > 0) {
      modal.update({
        content: `Phiên làm việc đã hết hạn. Quay lại trang đăng nhập sau ${secondsToGo} giây!`,
      });
    }
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
    logout();
  }, secondsToGo * 1000);
};
/**
 *
 * @param object
 * @returns new object with no property undefined or null
 */
export const cleanObject = object => {
  const newObj = Object.assign({}, object);
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === null || newObj[key] === undefined) delete newObj[key];
  });
  return newObj;
};

/**
 *
 * @param {s} input
 * @returns exp: (Nguyen Cuong => NC)
 */
export const getInitialLetterName = s => {
  let strs = s.split(' ');
  let result = [];
  strs.reverse().forEach(item => {
    result.length < 2 && result.push(item.substr(0, 1).toUpperCase());
  });
  return result.reverse().join('');
};
/**
 *
 * @param {initials}
 * @returns color based on string input
 */
export function getAvatarColor(initials) {
  let colorIndex = 0;
  if (initials) {
    let nameHash = 0;
    for (const s of initials) {
      nameHash += s.codePointAt(0) || 0;
    }
    colorIndex = nameHash % AVATAR_COLORS.length;
  }
  return `rgba(${AVATAR_COLORS[colorIndex]}, ${AVATAR_OPACITY})`;
}

/**
 * @param {number} input
 * @return number formatted, 100000 => 100,000
 */
export const formatNumber = number => {
  const value = number + '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

/**
 * @param func function debounced
 * @param waitFor time debounce
 */
export const debounce = (func, waitFor) => {
  let timeout = 0;

  const debounced = (...args) => {
    clearTimeout(timeout);
    setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

/**
 *
 * @param files: list File uploaded
 */
export function validateRatioFile(files) {
  function validateAFile(file) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = window.URL.createObjectURL(file);
      image.onload = () =>
        resolve({
          type: image.width / image.height > 1.5 ? 'success' : 'fail',
          file: file,
        });
      image.onerror = reject;
    });
  }

  return new Promise((resolve, reject) => {
    Promise.all(
      Object.values(files).map((file, index) =>
        validateAFile(file).then(res => {
          res.type === 'fail' &&
            openNotificationWithIcon(
              'error',
              'Upload ' + res.file.name + ' fail',
              'Ratio image is invalid. Please upload image have ratio 2:1!',
              (index + 1) * 1.5 + 0.5,
              'bottomRight',
            );
          return res;
        }),
      ),
    ).then(result => {
      resolve(
        result.filter(file => file.type === 'success').map(file => file.file),
      );
    });
  });
}

export const addScriptDynamic = src => {
  let script = document.createElement('script');
  script.setAttribute('src', src);
  document.head.appendChild(script);
};

export const toggleItemArrs = (arrs, item) => {
  const listXor = arrs.some(el => el === item)
    ? arrs.filter(el => el !== item)
    : [...arrs, item];
  return listXor;
};

export const generateRandomGradient = () => {
  var c1 = {
    r: Math.floor(255),
    g: Math.floor(35 + Math.random() * 220),
    b: Math.floor(Math.random() * 55),
  };
  var c2 = {
    r: Math.floor(255),
    g: Math.floor(35 + Math.random() * 220),
    b: Math.floor(Math.random() * 85),
  };
  c1.rgb = 'rgb(' + c1.r + ',' + c1.g + ',' + c1.b + ')';
  c2.rgb = 'rgb(' + c2.r + ',' + c2.g + ',' + c2.b + ')';
  return 'radial-gradient(at top left, ' + c1.rgb + ', ' + c2.rgb + ')';
};

export const LogoutConfirmCountDown = () => {
  let secondsToGo = 5;
  const modal = Modal.success({
    title: 'This is a notification message',
    content: `This modal will be destroyed after ${secondsToGo} second.`,
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
    modal.update({
      content: `This modal will be destroyed after ${secondsToGo} second.`,
    });
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
};
