import _ from 'lodash';
import { openAlertModal } from 'utils/helper';
import { getErrorMessage } from 'utils/errorMessages';

const fn = ({ actions = {}, process = () => {}, ...options }) => (
  payload,
  user,
  appRole,
  depRootId,
) => async (dispatch, getState, objDeps) => {
  const execute = async () => {
    // console.log('createOperation');
    const { startAction, successAction, failedAction } = actions;
    if (_.isFunction(startAction)) {
      dispatch(startAction(payload));
    }
    try {
      const result = await process({ payload, dispatch, getState });
      if (_.isFunction(successAction)) {
        dispatch(successAction({ result, params: payload }));
      }

      let onSuccess;
      if (options && options.onSuccess) {
        ({ onSuccess } = options);
      }
      if (_.isFunction(onSuccess)) {
        onSuccess({ dispatch, getState, params: payload, result, ...objDeps });
      }
      if (options?.successMessage) {
        // console.log(options.successMessage);
      }

      return result;
    } catch (error) {
      let onError;
      if (error.message === 'Network Error') {
        return null;
      }

      if (options && options.onError) {
        if (error.request.status === 401) {
          options.errorMessage = 'Hết phiên làm việc, vui lòng đăng nhập lại!';
        }
        ({ onError } = options);
      }
      if (_.isFunction(onError)) {
        onError();
      }

      // show error message on error
      let customMessage;
      if (error.response?.data) {
        customMessage = getErrorMessage(error.response?.data?.message);
      }

      await openAlertModal(
        'error',
        'Rất tiếc!',
        customMessage || options?.errorMessage || 'Lỗi không xác định',
        3,
      );

      if (_.isFunction(failedAction)) {
        dispatch(failedAction({ error }));
      }

      return null;
    }
  };

  if (options.requireConfirm) {
    // return Alert.alert(options.confirmTitle || 'Xác nhận', options.confirmMessage || '', [
    //   { text: 'Đồng ý', onPress: () => execute(), style: 'default' },
    //   { text: 'Huỷ', onPress: () => {}, style: 'cancel' },
    // ]);
  }

  return execute();
};

export default fn;
